import React from 'react';
import ClassNames from 'classnames';
import style from './paragraph.module.scss';

const Paragraph = ({ className, children, html }) => {
    if (html) {
        return <p className={ClassNames(style.paragraph, className)} dangerouslySetInnerHTML={{ __html: html}} />;
    }
    return <p className={[style.paragraph, className].join(' ')}>{children}</p>;
};

export default Paragraph;
