import React from 'react';
import style from './masonryItem.module.scss';

const MasonryItem = ({ children, className, ...rest}) => (
    <div className={[style.masonryItem, className].join(' ')}{...rest}>
        {children}
    </div>
);

export default MasonryItem;
