import React from 'react';
import classNames from 'classnames';
import { animated, useTransition, useSprings, config } from 'react-spring';
import { useInterval } from './../../hooks';
import Wrapper from '../wrapper';
import WrapperContent from '../wrapperContent';
import style from './quotes.module.scss';

const speed = 6000;


const QuotesContainer = ({ children, className, ...rest }) => (
    <div className={classNames(style.quotes, className)} {...rest}>
        <Wrapper>
            <WrapperContent>
                {children}
            </WrapperContent>
        </Wrapper>
    </div>
);

const Quotes = ({ className, data, ...rest }) => {
    const [index, set] = React.useState(0);

    const transitions = useTransition(data[index], item => item.id, {
        from: {opacity: 0, transform: 'translate(-40px, -50%)'},
        enter: {opacity: 1, transform: 'translate(0px, -50%)'},
        leave: {opacity: 0},
        config: config.molasses,
    });

    const springs = useSprings(data.length, data.map(item => ({
        background: (item.id === index) ? '#000000' : '#ffffff',
        borderWidth: (item.id === index) ? 0 : 1,
        opacity: (item.id === index) ? 1 : 0.4
    })));

    const [resetInterval] = useInterval(() => {
        // Your custom logic here
        set(state => (state + 1) % data.length);
    }, speed);

    const handleSelect = (e, index) => {
        set(index);
        resetInterval();
    };

    if (data.length === 1) {
        return (
            <QuotesContainer className={className} {...rest}>
                <div>
                    <blockquote>
                        {`${data[0].quote} _ ${data[0].name}. ${data[0].company}`}
                    </blockquote>
                </div>
            </QuotesContainer>
        )
    }

    return (
        <QuotesContainer className={className} {...rest}>
            <div className={style.quotesWrapper}>
                <div className={style.placeholder}>
                    {data.map((item, index) => {
                        return (
                            <blockquote key={index}>
                                {`${item.quote} _ ${item.name}. ${item.company}`}
                            </blockquote>
                        );
                    })}
                </div>
                {transitions.map(({item, props, key}) =>
                    <animated.blockquote key={key} style={{...props}}>{`${item.quote} _ ${item.name}. ${item.company}`}</animated.blockquote>
                )}
            </div>
            <ul className={style.indicators}>
                {springs.map((props, key) =>
                    <animated.li key={key} style={props} onClick={(e) => handleSelect(e, key)}/>
                )}
            </ul>
        </QuotesContainer>
    );
};

export default Quotes;
