import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import style from './logoGallery.module.scss';

const LogoGallery = ({ logos }) => {

    const childElements = logos.map((element, key) => (
        <li className={style.logoGalleryItem} key={key}>
            <Img
                fluid={element.experienceLogoImage.childImageSharp.fluid}
                alt={element.experienceLogoAlt}
            />
        </li>
    ));

    return (
      <div>
          <svg className={style.filter}>
              <filter id="monochrome">
                  <feColorMatrix type="matrix" result="grayscale"
                                 values="1 0 0 0 0
              1 0 0 0 0.05
              1 0 0 0 0.05
              0 0 0 0.3 0" >
                  </feColorMatrix>

              </filter>
          </svg>
          <ul
              className={style.logoGallery} // default ''
          >
              {childElements}
          </ul>
      </div>
    )
};

LogoGallery.propTypes = {
    logos: PropTypes.array,
};

export default LogoGallery;
