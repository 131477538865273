import React from 'react';
import { animated, useTransition, config } from 'react-spring';
import Wrapper from '../wrapper';
import WrapperContent from '../wrapperContent';
import shape from './../../images/shapes-2.svg';

import style from './headingHome.module.scss';

const speed = 6000;

const HeadingHome = ({ list }) => {
    const [index, set] = React.useState(0);
    const transitions = useTransition(list[index], item => item.id, {
        from: {opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
    });

    React.useEffect(() => {
        const animationInterval = setInterval(() => set(state => (state +1) % list.length), speed);

        return function() {
            clearInterval(animationInterval);
        }
    }, [list.length]);

    if (!list) return null;

    return (
        <div className={style.headingHome}>
            <img src={shape} alt="shape" className={style.backgroundArtefact}/>
            <Wrapper>
                <WrapperContent>
                    <div className={style.headingPlaceholder}>
                        {
                            list.map((listItem, key) =>
                                <h1 key={key} className={style.headingHomeText}>{listItem.title}</h1>
                            )
                        }
                    </div>
                    {
                        transitions.map(({ item, props, key }) =>
                            <animated.h1 key={key} className={style.headingHomeText} style={{...props}}>{item.title}</animated.h1>
                        )
                    }
                </WrapperContent>
            </Wrapper>
        </div>
    );
};

export default HeadingHome;
