import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from './center.module.scss';

const Center = ({ children, className, paddingTop, ...rest}) => (
    <div className={classNames(style.center, className, { [style.paddingTop]: paddingTop })} {...rest}>
        {children}
    </div>
);

Center.propTypes = {
    paddingTop: PropTypes.bool,
};

export default Center;
