import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { LanguageLayout } from '../containers/layouts';
import Actueel from './../containers/actueel';
import Button from './../components/buttons';
import Center from './../components/center';
import HeadingHome from './../components/headingHome';
import { Title } from './../components/headings';
import Paragraph from './../components/paragraph';
import Groups from './../components/groups/';
import Quotes from './../components/quotes';
import LogoGallery from './../containers/logoGallery';
import Wrapper from './../components/wrapper/';
import WrapperContent from './../components/wrapperContent/';

export const IndexPageTemplate = ({
    titleCarousel,
    expertisesGroup,
    expertisesList,
    actueel,
    pageContext,
    intl,
    quotes,
    experience
    }) => {

    if (!titleCarousel || !expertisesGroup || !expertisesList) {
        return null;
    }

    let formattedTitles = [];
    if (titleCarousel) {
        formattedTitles = titleCarousel.map((item, index) => {
            return {
                id: index,
                title: item.title,
            };
        });
    }

    // enhance quotes
    const quotesEnhanced = quotes.map((item, index) => {
        return {
            id: index,
            quote: item.quotesListQuote,
            name: item.quotesListName.toUpperCase(),
            company: item.quotesListCompany.toUpperCase()
        };
    });

    return (
        <>
            <HeadingHome list={formattedTitles}/>
            <Wrapper>
                <WrapperContent>
                    <Title>{expertisesGroup.expertisesTitle}</Title>
                    <Paragraph>
                    {expertisesGroup.expertisesText.split('\n').map((item, key) => {
                        return <React.Fragment key={key}>{item}<br/></React.Fragment>
                    })}
                    </Paragraph>
                    <Groups data={expertisesList} />
                    <Center paddingTop>
                        <Button to={`${pageContext.slug}${intl.formatMessage({id: 'mainmenu.url.expertise'})}`} type="GatbsyLink">{intl.formatMessage({id: 'homepage.allExpertisesButtonLabel'})}</Button>
                    </Center>
                </WrapperContent>
            </Wrapper>
            <Actueel data={actueel}>
                <Title>{intl.formatMessage({id: 'homepage.actueelTitle'})}</Title>
            </Actueel>
            {quotesEnhanced.length > 0 &&
            <Quotes data={quotesEnhanced}/>
            }
            <Wrapper>
                <WrapperContent>
                    <Title>{experience.experienceTitle}</Title>
                    <Paragraph>
                        {experience.experienceText.split('\n').map((item, key) => {
                            return <React.Fragment key={key}>{item}<br/></React.Fragment>
                        })}
                    </Paragraph>
                </WrapperContent>
                <LogoGallery logos={experience.experienceLogos} />
                <Center paddingTop>
                    <Button to={intl.formatMessage({id: 'mainmenu.url.cases'})} type="GatbsyLink">{intl.formatMessage({id: 'homepage.showAllCasesButtonLabel'})}</Button>
                </Center>
            </Wrapper>
        </>
    )
};

IndexPageTemplate.propTypes = {
    title: PropTypes.string,
    heading: PropTypes.string,
    langKey: PropTypes.string,
};

const IndexPageTemplateWithIntl = injectIntl(IndexPageTemplate);

const IndexPage = (props) => {
    const { frontmatter } = props.data.homePage;
    const expertises = props.data.homeExpertises.nodes;
    const actueel = props.data.actueel.nodes;
    const quotes = (frontmatter.quotesGroup && frontmatter.quotesGroup.quotesList) ? frontmatter.quotesGroup.quotesList : [];
    const experience = frontmatter.experienceGroup;

    let expertiseList = [];
    if (frontmatter.expertisesGroup && frontmatter.expertisesGroup.expertisesList) {
        expertiseList = frontmatter.expertisesGroup.expertisesList.map((item, index) => {
            const expertiseData = expertises.find(t => t.frontmatter.id === item.expertisesItemTitle);
            return {
                key: index,
                title: (expertiseData) ? expertiseData.frontmatter.title : item.expertisesItemTitle,
                slug: (expertiseData) ? expertiseData.fields.slug : null,
            }
        });
    }

    return (
        <LanguageLayout location={props.location}>
            <IndexPageTemplateWithIntl
                titleCarousel={frontmatter.titleCarousel}
                expertisesGroup={frontmatter.expertisesGroup}
                expertisesList={expertiseList}
                actueel={actueel}
                pageContext={props.pageContext}
                quotes={quotes}
                experience={experience}
            />
        </LanguageLayout>
    );
};

export default IndexPage

export const pageQuery = graphql`
    query IndexPageTemplate($langKey: String!) {
    homePage: markdownRemark(
        fields: { langKey: {eq: $langKey }}
        frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      frontmatter {
        titleCarousel {
          title
        }
        expertisesGroup {
          expertisesTitle
          expertisesText
          expertisesList {
            expertisesItemTitle
          }
        }
        upToDateGroup {
          upToDateTitle
        }
        quotesGroup {
          quotesList {
            quotesListName
            quotesListQuote
            quotesListCompany
          }
        }
        experienceGroup {
          experienceTitle
          experienceText
          experienceLogos {
            experienceLogoImage {
              publicURL
              childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
            }
            experienceLogoAlt
          }
        }
      }
    }
    homeExpertises: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "expertise-page"}}, fields: {langKey: {eq: $langKey}}}) {
        totalCount
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            id
            title
            expertiseListimage {
              publicURL
              childImageSharp {
                fixed(width: 64, height: 64) {
                    ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
    }
    actueel:allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC },
        limit:6,
        filter:{
            frontmatter: {
                templateKey: {in:["case-page", "news-page"]}
            },
            fields:{
                langKey:{eq: $langKey}
            }
    }) {
    totalCount
    nodes {
        fields {
            slug
        }
        frontmatter {
            date
            title
            metadata {
                excerpt
            }
            templateKey
                listimage {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 710) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
    }
  }
`;
