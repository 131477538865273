import React from 'react';
import { injectIntl } from 'react-intl';
import Masonry from 'react-masonry-component';
import Wrapper from '../../components/wrapper';
import WrapperContent from '../../components/wrapperContent';
import MasonryItem from '../../components/masonry/masonryItem';
import Button from './../../components/buttons';
import Center from './../../components/center';
import Card from './../../components/card';
import CardImage from './../../components/cardImage';
import CardContent from './../../components/cardContent';
import CardActions from './../../components/cardActions';
import BlendImage from './../../components/blendImage';

import style from './actueel.module.scss';
import {H3} from '../../components/headings';

const masonryOptions = {
    transitionDuration: 0
};

const Actueel = ({ intl, children, data }) => (
    <div className={style.actueel}>
        <Wrapper>
            <WrapperContent>
                {children}
                <Masonry
                    className={'my-gallery-class'} // default ''
                    options={masonryOptions} // default {}
                >
                    {data.map((item, index) => (
                        <MasonryItem key={index}>
                            <Card>
                                {(item.frontmatter && item.frontmatter.listimage && item.frontmatter.listimage.childImageSharp) &&
                                <CardImage>
                                    <BlendImage
                                        fluid
                                        src={item.frontmatter.listimage.childImageSharp.fluid}
                                        strength={4}
                                    />
                                </CardImage>
                                }
                                <CardContent>
                                    <H3>{item.frontmatter.title}</H3>
                                    <p>{item.frontmatter.metadata.excerpt}</p>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        to={item.fields.slug}
                                        type="GatbsyLink"
                                        secondary={true}
                                        primary={false}
                                        large={true}
                                    >
                                        {intl.formatMessage({id: 'news.readMoreLabel'})}
                                    </Button>
                                </CardActions>
                            </Card>
                        </MasonryItem>
                    ))}
                </Masonry>
                <Center paddingTop>
                    <Button to={intl.formatMessage({id: 'mainmenu.url.cases'})} className={style.buttons} type="GatbsyLink">{intl.formatMessage({id: 'homepage.showAllCasesButtonLabel'})}</Button>
                    <Button to={intl.formatMessage({id: 'mainmenu.url.news'})} className={style.buttons} type="GatbsyLink">{intl.formatMessage({id: 'homepage.showAllNewsButtonLabel'})}</Button>
                </Center>
            </WrapperContent>
        </Wrapper>
    </div>
);

export default injectIntl(Actueel);
